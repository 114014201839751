<template>
    <div id="dashboard" class="d-flex-r-ast-w-g1-s0-gap15">
        <dashboard-header class="fullWidth"/>
        <dashboard-wallet class="basis-400"/>
        <dashboard-live-price class="basis-400"/>
    </div>
</template>

<script>
    import DashboardHeader from "@/components/Panel/Dashboard/DashboardHeader";
    import DashboardWallet from "@/components/Panel/Dashboard/DashboardWallet";
    import DashboardLivePrice from "@/components/Panel/Dashboard/DashboardLivePrice";

    export default {
        name: "Dashboard",
        components: {DashboardLivePrice, DashboardWallet, DashboardHeader}
    }
</script>

<style lang="scss">
    #dashboard {
        background-color: transparent !important;
        align-content: flex-start;
    }
</style>