<template>
    <the-drop-down class="dashboard-header-vector" align="right">
        <template #label>
            <inline-svg width="62" :src="require('@/assets/Icons/Vector.svg')"/>
            <span class="d-flex-c-afs-jsb py-5">
                <p>aaaaa@bbbb.cc</p>
                <small>
                    <b>کاربر سطح 2</b>
                    (کارمزد 0.35%)
                </small>
            </span>
        </template>
        <template #icon>
            <span></span>
        </template>
        <template #dropdown>
            <div class="levels card">
                <span></span>
                <span></span>
                <span>کارمزد</span>
                <template v-for="level in levels">
                    <span :key="level.icon" :class="{current : level.current}">
                        <img width="38" :src="require(`@/assets/Icons/Levels/${level.icon}.png`)" :alt="level.icon">
                    </span>
                    <span :key="level.name" :class="{current : level.current}">
                        <h4>{{level.name}}</h4>
                        <p>{{level.need}}</p>
                    </span>
                    <span :key="level.fee" :class="{current : level.current}">{{level.fee}}</span>
                </template>
            </div>
        </template>
    </the-drop-down>
</template>

<script>
    import TheDropDown from "@/components/Tools/TheDropDown";

    export default {
        name: "DashboardHedaerVector",
        components: {TheDropDown},
        data(){
            return{
                levels:[
                    {
                        name : 'کاربر سطح 1',
                        icon : 'bronze',
                        need : ' 0 - 5 میلیون تومان',
                        fee : '0.35%',
                    },
                    {
                        name : 'کاربر سطح 2',
                        icon : 'silver',
                        need : ' 50 - 100 میلیون تومان',
                        fee : '0.30%',
                        current : true
                    },
                    {
                        name : 'کاربر سطح 3',
                        icon : 'gold',
                        need : ' 100 - 150 میلیون تومان',
                        fee : '0.25%',
                    },
                    {
                        name : 'کاربر سطح vip',
                        icon : 'vip',
                        need : '> 150 میلیون تومان',
                        fee : '0.15%',
                    },
                ]
            }
        }
    }
</script>

<style lang="scss">
    .dashboard-header-vector {
        .levels {
            display: grid;
            grid-template-columns: 55px 150px 50px;
            padding: 5px;

            span:nth-of-type(3n+1) {
                &.current{
                    border-top-right-radius: var(--m-radius);
                    border-bottom-right-radius: var(--m-radius);
                }
            }

            span:nth-of-type(3n+2) {
                font-size: var(--m-font);
            }

            span:nth-of-type(3n+3) {
                font-size: var(--s-font);
                &.current{
                    border-top-left-radius: var(--m-radius);
                    border-bottom-left-radius: var(--m-radius);
                }
            }

            span {
                padding: 5px;
                &.current{
                    background-color: var(--side-color);
                    color: var(--dark);
                }
            }
        }
    }
</style>