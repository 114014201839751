<template>
    <div class="dashboard-header l-radius p-15 d-flex-jsb-afs-w">
       <dashboard-hedaer-vector/>
        <a :href="$href({name : 'Profile'})" @click.prevent="$push({name : 'Profile'})" class="authorize m-font px-10 py-5">ادامه فرآیند اهراز هویت</a>
    </div>
</template>

<script>
    import DashboardHedaerVector from "@/components/Panel/Dashboard/DashboardHedaerVector";

    export default {
        name: "DashboardHeader",
        components: {DashboardHedaerVector}
    }
</script>

<style lang="scss">
    .dashboard-header {
        background-color: var(--gray-box);
        .authorize{
            color: var(--yellow);
            border: 1px solid var(--yellow);
            border-radius: var(--xs-radius);
        }
    }
</style>