<template>
    <div id="dashboard-wallet" class="d-flex-c-ast-jsb-gap20 l-radius p-15">
        <div class="d-flex-jsb-w-g1-s0-ac">
            <p class="n-font">
                موجودی کیف پول تومانی 7,278,000 تومان
            </p>
            <div class="buttons d-flex-r-g1-s0-ast-gap10">
                <button type="button" @click="TomanDepositModal = true"
                        class="gradient-template-gold-mini-trans-linear back s-radius px-5 d-flex-ac-jc-gap10 px-10">
                    <inline-svg :src="require('@/assets/Icons/BUY-icon.svg')"/>
                    واریز
                </button>
                <button type="button" @click="TomanWithdrawModal = true"
                        class="gradient-template-gray-linear-gradient back s-radius px-5 d-flex-ac-jc-gap10 px-10">
                    <inline-svg :src="require('@/assets/Icons/SELL-icon.svg')"/>
                    برداشت
                </button>
            </div>
        </div>
        <form class="d-flex-c-jfs-ast">
            <div v-for="[relatedCoin,value] in Object.entries(wallets).filter((key,i) => key[0]!=='TOMAN' && i<5)"
                 :for="relatedCoin + '-radio'" class="items d-flex-r-jsb-ac px-15" :key="value.unit">
                <span class="d-flex-r-ac-jfs-gap10">
                    <img width="20" :src="require('@/assets/Icons/coins/'+relatedCoin+'.png')" :alt="relatedCoin">
                    {{$coinLabel[relatedCoin]}}
                </span>
                <data value="0.0008">{{value.credit}}</data>
            </div>
        </form>
        <div class="d-flex-jsb-w-g1-s0-ac" style="height: 40px">
            <p class="n-font">
                موجودی دارایی 7,278,000 تومان
            </p>
            <a class="gradient-template-gold-linear grow-0 text-gradient d-flex-r-ac-jfs-gap10"
               :href="$href({name : 'Wallet'})" @click.prevent="$push({name : 'Wallet'})">
                همه کیف پول ها
                <inline-svg :src="require('@/assets/Icons/arrow-left.svg')"/>
            </a>
        </div>
        <toman-withdraw-modal v-model="TomanWithdrawModal"/>
        <toman-deposit-modal v-model="TomanDepositModal"/>
    </div>
</template>

<script>
    import TomanWithdrawModal from "@/components/Panel/Wallet/Modal/TomanWithdrawModal";
    import TomanDepositModal from "@/components/Panel/Wallet/Modal/TomanDepositModal";

    export default {
        name: "DashboardWallet",
        components: {TomanDepositModal, TomanWithdrawModal},
        data() {
            return {
                TomanWithdrawModal: false,
                TomanDepositModal: false,
                CryptoDepositModal: false,
                CryptoWithdrawModal: false,
                coin: '',
                wallets: {
                    TOMAN: {
                        totalBalance: '',
                        credit: '7,278,000'
                    },
                    BITCOIN: {
                        totalBalance: '',
                        credit: '0.0008',
                        unit: 'BTC'
                    },
                    ETHEREUM: {
                        totalBalance: '',
                        credit: '123',
                        unit: 'ETH'
                    },
                    CLASSIC_ETHEREUM: {
                        totalBalance: '',
                        credit: '123',
                        unit: 'ETC'
                    },
                    DOGE_COIN: {
                        totalBalance: '',
                        credit: '123',
                        unit: 'DOGE'
                    },
                    DASH: {
                        totalBalance: '',
                        credit: '123',
                        unit: 'DASH'
                    },
                    TETHER: {
                        totalBalance: '',
                        credit: '123',
                        unit: 'USDT'
                    },
                }
            }
        }
    }
</script>

<style lang="scss">
    #dashboard-wallet {
        background-color: var(--gray-box);

        p {
            text-align: right;
        }

        a {
            text-align: left;
        }

        .buttons {
            button {
                height: 30px;
                cursor: pointer;
                font-weight: 600;

                &:nth-of-type(2) {
                    color: var(--white);
                }
            }
        }

        form {
            .items {
                height: 45px;
                transition: border-color 0.3s ,background-color 0.3s;
                border-bottom: 1px solid var(--border);

                &:hover {
                    background-color: var(--side-item);
                    border-radius: var(--s-radius);
                    border-color: transparent;
                }
            }
        }
    }
</style>