<template>
    <div id="dashboard-live-price" class="l-radius p-15 d-flex-c-ast-jsb-gap20">
        <table class="n-font">
            <tr>
                <th>بازار</th>
                <th>قیمت لحظه ای</th>
                <th>تغییرات</th>
            </tr>
            <tr v-for="item in items" :key="item.name">
                <td>
                    <span class="d-flex-r-ac-jfs-gap10">
                        <img width="20" :src="require('@/assets/Icons/coins/'+item.relatedCoin+'.png')" :alt="item.relatedCoin">
                        {{$coinLabel[item.relatedCoin]}}
                    </span>
                </td>
                <td>
                    {{item.price}}
                </td>
                <td dir="ltr" class="selected" :style="{color : item.change >= 0 ? 'var(--buy)' : 'var(--sell'}">
                    {{item.change >= 0 ? '+ ' : '- ' }}{{Math.abs(item.change)}}%
                </td>
            </tr>
        </table>
        <div class="d-flex-jsb-w-g1-s0-ac">
            <div class="d-flex-ac-jfs-gap25">
                ارز پایه
                <span class="tabs d-flex-ac gradient-template-linear-sidebar">
                    <img @click="toman=true" :class="{ 'currentTab' : toman}" width="40"
                         src="../../../assets/Icons/coins/TOMAN.png" alt="TOMAN"/>
                    <img @click="toman=false" :class="{ 'currentTab' : !toman}" height="38" width="38"
                         src="../../../assets/Icons/coins/TETHER.png" alt="TOMAN"/>
                </span>
            </div>
            <a class="gradient-template-gold-linear grow-0 text-gradient d-flex-r-ac-jfs-gap10"
               :href="$href({name : 'Markets'})" @click.prevent="$push({name : 'Markets'})">
                همه بازار ها
                <inline-svg :src="require('@/assets/Icons/arrow-left.svg')"/>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DashboardLivePrice",
        data() {
            return {
                toman: true,
                items: [
                    {
                        relatedCoin: 'BITCOIN',
                        price: '1,245,145',
                        change: -2.55,
                    },
                    {
                        relatedCoin: 'DOGE_COIN',
                        price: '1,451',
                        change: 2.55,
                    },
                    {
                        relatedCoin: 'ETHEREUM',
                        price: '45,415',
                        change: -2.55,
                    },
                    {
                        relatedCoin: 'TETHER',
                        price: '20,145',
                        change: 2.55,
                    },
                ]
            }
        }
    }
</script>

<style lang="scss">
    #dashboard-live-price {
        background-color: var(--gray-box);

        table {
            border-collapse: collapse;
            border-bottom: 1px solid var(--border);

            tr {
                &:first-of-type {
                    border-bottom: 1px solid var(--border);
                    height: 30px;
                }

                &:nth-of-type(2) {
                    td {
                        padding-top: 30px;
                        padding-bottom: 10px;
                    }
                }

                &:nth-of-type(n+2) {
                    height: 45px;
                    cursor: pointer;

                    &:hover {
                        color: var(--yellow1);
                    }
                }

                th {
                    &:first-of-type {
                        text-align: right;
                    }

                    &:last-of-type {
                        text-align: left;
                    }
                }

                td {
                    &:first-of-type {
                        text-align: right;
                    }

                    &:last-of-type {
                        text-align: left;
                    }
                }
            }
        }

        .tabs {
            position: relative;

            &:before {
                content: '';
                background-color: var(--yellow2);
                background-image: var(--linear-template);
                position: absolute;
                width: 52px;
                height: 52px;
                right: 25%;
                top: 50%;
                transform: translate(50%, -50%);
                border-radius: 50%;
            }

            img {
                order: 1;
                cursor: pointer;
                &.currentTab {
                    transform: scale(calc(50 / 40));
                    order: 0;
                    cursor: default;
                }
            }
        }
    }
</style>